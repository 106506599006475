<template>
  <b-card>
    <b-card-title>Policies</b-card-title>
    <b-card-sub-title>
      Guests will see these policies when they will search for properties. Policies should be written very clearly and specifically so the guests can understand easily.
    </b-card-sub-title>
    <!-- cancellation policy -->
    <b-row class="my-1 border-bottom mx-0">
      <b-col
        md="l2"
        class="w-100"
      >
        <b-form-group
          label="Please write cancellation policy"
          label-for="cancellation-policy"
        >
          <quill-editor
            id="cancellation-policy"
            v-model="policyData.cancellation_policy"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="l2"
        class="w-100"
      >
        <b-form-group
          label="Please write cancellation text"
          label-for="cancellation-text"
        >
          <quill-editor
            id="cancellation-text"
            v-model="policyData.cancellation_text"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <!-- refund policy -->
    <b-row
      class="my-1 mx-0 border-bottom"
    >
      <b-col
        md="l2"
        class="w-100"
      >
        <b-form-group
          label="Refund Policy"
          label-for="refund-policy"
        >
          <quill-editor
            id="refund-policy"
            v-model="policyData.refund_policy"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <!--child picker policy-->
    <b-row
      class="my-1 service-charge border-bottom"
    >
      <b-col
        md="6"
      >
        <!-- pricing -->
        <b-form-group
          label="Child Pricing Policy"
          label-for="pricing"
        >
          <b-input-group
            :prepend="currency_code ? currency_code : 'BDT'"
          >
            <b-form-input
              id="pricing"
              v-model="policyData.child_pricing_policy"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
      >
        <!-- pricker text -->
        <b-form-group
          label="Child Picker Text"
          label-for="picker-text"
        >
          <b-input-group>
            <b-form-input
              id="picker-text"
              v-model="policyData.child_picker_text"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- text and infant pickcer text -->
    <b-row
      class="my-1 service-charge border-bottom"
    >
      <b-col
        md="12"
      >
        <!-- text -->
        <b-form-group
          label="Child Policy Text"
          label-for="text"
        >
          <quill-editor
            id="text"
            v-model="policyData.child_policy_text"
          />
        </b-form-group>
      </b-col>
      <!-- picker text -->
      <b-col
        md="6"
      >
        <b-form-group
          label="Adult Picker Text"
          label-for="adult-text"
        >
          <b-input-group>
            <b-form-input
              id="infant-text"
              v-model="policyData.adult_picker_text"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
      >
        <b-form-group
          label="Infant Picker Text"
          label-for="infant-text"
        >
          <b-input-group>
            <b-form-input
              id="infant-text"
              v-model="policyData.infant_picker_text"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <!--Pax info-->
    <b-row
      class="my-1 service-charge"
    >
      <b-col
        md="6"
      >
        <!-- min pax-->
        <b-form-group
          label="Min Pax"
          label-for="min-pax"
        >
          <b-input-group>
            <b-form-input
              id="min-pax"
              v-model="policyData.minimum_availability"
            />
          </b-input-group>
        </b-form-group>
        <!-- max pax-->
        <b-form-group
          label="Max Pax"
          label-for="max-pax"
        >
          <b-input-group>
            <b-form-input
              id="max-pax"
              v-model="policyData.maximum_availability"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <!-- starting price -->
      <b-col
        md="6"
      >
        <b-form-group
          label="Starting Price"
          label-for="starting-price"
        >
          <b-input-group
            :prepend="currency_code ? currency_code : 'BDT'"
          >
            <b-form-input
              id="starting-price"
              v-model="policyData.starting_price"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <!-- Submit -->
    </b-row>
    <div class="w-100 text-right pb-1">
      <b-button
        variant="primary"
        :disabled="isBtnLoading"
        @click="updatePolicyData()"
      >
        <b-spinner
          v-if="isBtnLoading"
          small
        />
        Submit
      </b-button>
    </div>
    <div
      class="text-right py-2 border-top"
    >
      <b-button
        variant="outline-primary"
        :to="`/tour-register/step-3/${$route.params.id}`"
      >
        Tags
        <feather-icon
          icon="ArrowRightIcon"
          size="12"
        />
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import api from '@/libs/api'
import helper from '@/mixins/helper'
import tour from '@/mixins/tour'

export default {
  name: 'Policies',
  components: {
    quillEditor,
  },
  mixins: [helper, tour],
  data() {
    return {
      content: '',
      policyData: {
        adult_picker_text: '',
        cancellation_policy: '',
        cancellation_text: '',
        refund_policy: '',
        child_policy_text: '',
        child_pricing_policy: '',
        child_picker_text: '',
        infant_picker_text: '',
        maximum_availability: '',
        starting_price: '',
      },
      currency_code: this.$store.state.tour.property.currency_code,
      isBtnLoading: false,
    }
  },
  mounted() {
    this.isTourValid(this.$route.params.id, this.getPolicyData)
  },

  methods: {
    getPolicyData() {
      api.getData(`/tour/admin_services/tour/${this.$route.params.id}/policy/`, true)
        .then(response => {
          if (response.data.status) {
            this.policyData = response.data.result
          } else {
            this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
          }
        }).catch(() => {
          this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
        }).finally(() => {
          window.scrollTo(0, 0)
        })
    },
    updatePolicyData() {
      this.isBtnLoading = true
      api.updateData(`tour/admin_services/tour/${this.$route.params.id}/tour_update/`, true, this.policyData).then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success', 'policy updated successfully')
          this.$router.push(`/tour-register/step-3/${response.data.result.id}`)
        } else {
          this.notificationMessage('danger', 'Xicon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isBtnLoading = false
      })
    },
  },
}
</script>
